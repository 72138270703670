.dcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button3:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.616),
    0 17px 50px 0 rgba(0, 0, 0, 0.452);
}

.PasswordEye{
  width: 45px;
  height: 45px;
}

.PasswordEye2{
  width: 19px;
  height: 19px;
}

.LoginInputText {
  /* text-align: center; */
  height: 30px;
}

.LoginContainer {
  width: 65%;
  /* margin-left: 25%;
  margin-right: 25%; */
  border: 1px solid #000000;
  flex-direction: column;
  background-color: white;
}

.ButtonLogin {
  margin-top: 40px;
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border-width: 1;
  background-color: black;
  /* border-color: lightblue; */
  justify-content: center;
}

.MainPageDateContainer {
  display: flex;
  align-items: center;
  width: 30%;
}

.LoginUsername {
  width: 80%;
  height: auto;
  padding-top: 6%;
  flex-direction: row;
}

.LoginUsername2 {
  width: 80%;
  height: auto;
  flex-direction: row;
}

.LoginUsernameLabel {
  width: 10%;
  font-weight: bold;
}
.HeaderBold {
  font-weight: bold;
}
.navbar-brand{
  color: black !important;
}

.navbar-brand:hover {
  color: white !important;
}

.nav-link{
  color: black !important;
}

.nav-link:hover {
  color: white !important;
}
/* width: '70%', left: 250 */
.AlertPage {
  width: 70%;
  left: 250px;
}   

.LoginWallpaper {
  background-image: 
  url(
"./image/WMS2.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat:no-repeat; 
}

/* .DOWallpaper {
  background-image: 
  url(
"https://static.vecteezy.com/system/resources/previews/004/949/058/original/delivery-service-a-courier-in-a-medical-mask-holds-a-box-with-an-order-against-the-background-of-a-cargo-van-the-concept-of-an-online-delivery-service-to-your-home-and-office-illustration-vector.jpg");
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat:no-repeat; 
} */

.LoginContainer {
  display: flex;
  flex-direction: column;
  padding: 50px;
  border: 1px solid transparent;
  width: 35%;
  border-radius: 20px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.616),
    0 17px 50px 0 rgba(233, 232, 232, 0.452);
}

.USernameLogin {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.ChangePassName {
  width: 100px;
  font-size: 18px;
  font-weight: bold;
}

.ChangePassInput {
  display: flex;
  flex-direction: row;
  width: 40%;
}

.ChangePAsswordTextView{
  flex-direction: row;
  margin-bottom: 20px;
  /* align-items: center; */
  width: 100%;
}

.PasswordEye{
  width: 40px;
  height: 40px;
}

.pushable {
  background: rgb(11, 108, 146);
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.front {
  display: block;
  padding: 5px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: rgb(14, 149, 202);
  color: white;
  transform: translateY(-6px);
  will-change: transform;
  transition: transform 150ms;
}

.pushable:hover .front {
    transform: translateY(-6px);
  }

.pushable:active .front {
  transform: translateY(-2px);
}

.ShadowButton:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.100),
  0 5px 5px 0 rgba(0, 0, 0, 0.100);
}

.ContainerShadow {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.100),
    0 5px 5px 0 rgba(0, 0, 0, 0.100);
}

.POButton {
  font-weight: bold;
  cursor: pointer;
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding: 5px;
  border-radius: 100px;
}

.POButton:hover {
  color: white;
  transition: color 0.5s;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.100),
    0 5px 5px 0 rgba(0, 0, 0, 0.100);
}

.EditWord {
  font-size: 13px;
}

.EditWord:hover {
  transition: font-size 0.7s;
  font-size: 15px;
}

.TongRubbish {
  font-size: 15px;
}

.TongRubbish:hover {
  transition: font-size 0.7s;
  font-size: 19px;
}

.scrollhost {
  scrollbar-width: none;
 -ms-overflow-style: none;
}

.loadingimages {
  width: 350px;
  height: 150px;
  border-radius: 50px;
}

.scrollhost::-webkit-scrollbar {
  width: 10px;
}

.scrollhost::-webkit-scrollbar-track  {
  background: transparent;
}

.scrollhost::-webkit-scrollbar-thumb   {
  background: rgb(119, 119, 119);
  border-radius: 20px;
}

.scrollhost::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

  /* DOModule */
  .SummaryDOContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    padding: 20px;
    font-size: 25px;
  }

  .SummaryDOAmountContainer {
    width: 100%;
    height: 60%;
    display: flex;
    color: white;
    padding: 20px;
    font-size: 40px;
  }

  .DetailsIcon{
    /* color: white; */
    width: 38px;
    height: 38px;
  }
  .DetailsIcon:hover{
    width: 45px;
    height: 45px;
  }

  /* Add Quotation */
    /* DashBoard */
.DashBoardContainer {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    padding: 20px;
    font-size: 25px;
}

.DashBoardMoreinfoContainer {
  width: 100%;
  height: 20%;
  display: flex;
  color: white;
  padding: 20px;
  font-size: 18px;
}

.dashboardFeaturesButton {
  cursor: pointer;
  opacity: 0;
  transform: translateY(20px);
  /* background: white; */
  transition: opacity 0.8s, transform 0.8s;
}

.dashboardFeaturesButton.active {
  cursor: pointer;
  opacity: 1;
  transform: translateY(0);
  background: white;
  /* transition: font-size 0.6s ease-out 80ms */
  transition: font-size 0.8s, ease-out 0.7s;
}

.dashboardFeaturesButton.active:hover {
  font-size: 20px;
  box-shadow: 0 12px 16px 0 rgba(216, 215, 215, 0.616),
    0 17px 50px 0 rgba(233, 232, 232, 0.452);
}

#page-wrap {
  text-align: center;

  background-color: aqua !important;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* The rest copied directly from react-burger-menu docs */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
  color: #373a47;
  /* padding: 20px; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.LogoutPoint {
  background-color: #FF4316;
}

.LogoutPoint:hover {
  transition: background-color 0.6s ease-out 80ms;
  background-color: #FF8C70;
}

.DashboardBackground {
  background-image: 
  url(
"./image/Walllapercount.jpg");
  background-size: cover;
  background-position: center;
  background-repeat:no-repeat;
}

.CustomerBackground {
  background-image: 
  url(
"./image/Customer.jpg");
  background-size: cover;
  background-position: center;
  background-repeat:no-repeat;
}

.ErrorSign{
  width: 70px;
  height: 55px;
}

.SuccessSign{
  width: 70px;
  height: 65px;
}

/* Button */
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

/* CSS */
.button-53 {
  background-color: #3DD1E7;
  border: 0 solid #E5E7EB;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.75rem;
  padding: .75rem 1.65rem;
  position: relative;
  text-align: center;
  text-decoration: none #000000 solid;
  text-decoration-thickness: auto;
  width: 100%;
  max-width: 460px;
  position: relative;
  cursor: pointer;
  transform: rotate(-2deg);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-53:focus {
  outline: 0;
}

.button-53:after {
  content: '';
  position: absolute;
  border: 1px solid #000000;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}

.button-53:hover:after {
  bottom: 2px;
  left: 2px;
}

@media (min-width: 768px) {
  .button-53 {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

/* CSS */
.button-55 {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-55:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}

.button-55:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

.slideshow-container {
  display: inline-block;
  width: 100vh;
  height: 60vh;
  /* width: 750px; */
  /* height: 400px; */
  /* position: relative; */
  /* margin: auto; */
}

 /* Caption text */
 .text {
   color: #ffffff;
   font-size: 15px;
   padding: 8px 12px;
   position: absolute;
   bottom: 8px;
   width: 100%;
   text-align: center;
 }
 /* Number text (1/3 etc) */
 .numbertext {
   color: #ffffff;
   font-size: 12px;
   padding: 8px 12px;
   position: absolute;
   top: 0;
 }
 /* The dots/bullets/indicators */
 .dot {
   cursor: pointer;
   height: 15px;
   width: 15px;
   margin: 0 2px;
   background-color: #999999;
   border-radius: 50%;
   display: inline-block;
   transition: background-color 0.6s ease;
 }
 .active,
 .dot:hover {
   background-color: #111111;
 }
 /* Fading animation */
 .fade {
   -webkit-animation-name: fade;
   -webkit-animation-duration: 1.5s;
   animation-name: fade;
   animation-duration: 1.5s;
 }
 @-webkit-keyframes fade {
   from {
     opacity: .4
   }
   to {
     opacity: 1
   }
 }
 @keyframes fade {
   from {
     opacity: .4
   }
   to {
     opacity: 1
   }
 }
 /* On smaller screens, decrease text size */
 @media only screen and (max-width: 300px) {
   .prev,
   .next,
   .text {
     font-size: 11px
   }
 }

 .AttentionsLogo{
  width: 100px;
  height: 100px;
}

.ModalMEssage {
  /* border-radius: 20px; */
  box-shadow: 0 12px 16px 0 rgba(27, 27, 27, 0.616),
    0 17px 50px 0 rgba(36, 35, 35, 0.900);
}

 /* Images Slider Desig*/
 .PointerSliderImage {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 100px;
 }

 .PointerSliderImage:hover {
  width: 20px;
  height: 20px;
  transition: all 0.6s ease;
 }

 .BackgroundImageSlider {
  background-size: cover;
  background-position: center;
  background-repeat:no-repeat;
  width: 100%;
  height: 65vh;
  transition: all 0.6s;
  /* transition-timing-function: ease-in-out; */
 }

 .prev {
  font-size: 16px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  color: white !important;
  padding: 18px;
 }

 .prev:hover {
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 30px;
  transition: all 0.6s ease;
 }

 .next {
  font-size: 16px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
  color: white !important;
  padding: 18px;
 }

 .next:hover {
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 30px;
  transition: all 0.6s ease;
 }

 .SelectedMaster {
  cursor: pointer;
  width: 40%;
  height: 10vh;
  font-size: 30px;
 }

 .SelectedMaster:hover {
  width: 45%;
  height: 15vh;
  font-size: 35px;
  transition: all 0.6s ease-out;
 }

 .SelectedMaster:active {
  width: 43%;
  height: 10vh;
  font-size: 35px;
  color: black;
  background-color: white !important;
 }

 .Description {
  font-size: 15px !important;
 }

/* xxxx */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 25px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.custom-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.display-file-upload {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  background: linear-gradient(270deg, #3fa1a9, #79f1a4);
  margin-bottom: 25px;
}

.img-wrap{
  position: relative;
  width: 250px;
  height: 230px;
  overflow: hidden;
  border-radius: 50%;
}
.img-upload:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 230px;
  height: 210px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-upload:hover:before{
 opacity: 1;
}

.ExcelfileUpload {
  width: 48px;
  height: 48px;
  cursor: pointer;
  padding: 3px;
  border-radius: 100px;
  border: 1px solid white;
}

.ExcelfileUpload:hover {
  padding: 0px;
  border-radius: 100px;
  border: 1px solid white;
  transition: all 0.6s ease;
}


input[type="file"] {
  display: none;
}

img {
  width: auto;
  height: 100%;
}

.ProductMasterSelect {
  background-color: #333333;
  color: white;
}

.CustomerMasterSelect {
  background-color: #333333;
  color: white;
}

.StoreMasterSelect {
  background-color: #333333;
  color: white;
}

.CategoryMasterSelect {
  background-color: #333333;
  color: white;
}

.DepartmentMasterSelect {
  background-color: #333333;
  color: white;
}

.UserManagementSelect {
  background-color: #333333;
  color: white;
}

.ProductMasterSelect:hover {
  color: black;
}
.CustomerMasterSelect:hover {
  color: black;
}
.StoreMasterSelect:hover {
  color: black;
}
.CategoryMasterSelect:hover {
  color: black;
}
.DepartmentMasterSelect:hover {
  color: black;
}
.UserManagementSelect:hover {
  color: black;
}

.cannothightlight {
  user-select: none;
}

.HeaderButton {
  font-weight: bold;
  cursor: pointer;
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding: 8px;
  border-radius: 5px;
  color: white;
}

.HeaderButton:hover {
  color: white;
  transition: color 0.5s;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.100),
    0 5px 5px 0 rgba(0, 0, 0, 0.100);
}
.MainPageDateContainerWidth {
  display: flex;
  align-items: center;
  width: 100%;
}

.service-slow-pop-up-text {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s, transform 0.8s;
}

.service-slow-pop-up-text.active {
  opacity: 1;
  background-color: rgba(245, 248, 250, .0);
  transform: translateY(0);
}

/* #about video {
  z-index: -1;
  object-fit: cover;
} */

.video-bg {
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 35vh;
  object-fit: cover;
}

.bg-overlay {
  position: absolute;
  width: 100%;
  height: 45vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  margin: 0;
  padding: 0;
  font-size: 40px;
  background-color: rgba(0, 0, 0, 0.3);
}

.bgContainer {
  width: 100%;
  height: 100vh;
}

.videoplay {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 45vh;
  object-fit: cover;
}

.overplay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 45vh;
}
/* BarChart CSS*/
.detail-bar-chart {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 45vh;
  padding: 10px;
  background: linear-gradient(to top, #232222,#302f2f, #151515);
}

.detail-bar-chart:hover {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 50vh;
  padding: 10px;
  /* background: white !important; */
  transition: all 0.6s ease-out;
  background: linear-gradient(to top, #232222,#302f2f, #151515);
}

.detail-bar-chart:active {
  width: 43%;
  height: 45vh;
  font-size: 35px;
  color: black;
  transition: all 0.6s ease-out;
  background: white !important;
 }

.detail-bar {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* background-color: #0074d9; */
  margin: 5px;
}

.bar-label {
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
}

.bar-value {
  color: #fff;
  font-size: 18px;
}

.bar-description {
  color: #333;
  font-size: 12px;
}

/* PieChart.css */
.pie-chart-container {
  width: 300px;
  height: 300px;
  position: relative;
}

.pie-chart {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: rotate(-90deg);
}

.slice {
  position: absolute;
  width: 50%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  transform-origin: 100%;
  animation: fill 2s;
}

@keyframes fill {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: white;
}

/* Line Chart */
.line-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin: 20px;
  background-color: #f5f5f5;
}

.line-chart svg {
  width: 100%;
  height: 100%;
}

.line-chart path {
  fill: none;
  stroke: #0074d9; /* Line color (blue in this case) */
  stroke-width: 2;
}

.img-wrapforExcelUpload{
  position: relative;
  width: 100px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
}

.img-uploadExcel:before{
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #63d3a6;
  width: 100px;
  height: 80px;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}
.img-uploadExcel:hover:before{
 opacity: 1;
}
